import React from 'react';
import {graphql} from 'gatsby';
import {
    PageContainerDPC,
    CardElencoDPC,
    FormFiltriDPC,
    PrintButtonDPC,
    CardHorizontalDPC,
    CardHorizontalContainerDPC
} from '@dpc-fe/shared';
import DpcPaginator from "@dpc-fe/shared/components/dpc-paginator";
import {getSlug, prop, getCategoriaPrimariaEN} from "../../functions/functions";
import {buildMenu} from "@dpc-fe/shared/js/shared-functions";
import moment from "moment";

class ElencoApprofondimenti extends React.Component {

    constructor(props) {
        super(props);
        this.itemsPerPage = 5;
        this.filtra = this.filtra.bind(this);
        this.setPage = this.setPage.bind(this);
        this.fullData = this.props.data.allNodeApprofondimento.edges.filter(item => item.node.field_codice_lingua === true).sort((a, b) => {
            return moment(a.node.field_data).isBefore(moment(b.node.field_data), "minute") ? 1 : -1;
        });
        this.data = [...this.fullData];
        if(typeof window !== 'undefined' && sessionStorage.getItem('approfondimentiEnPage')) {
            let intervalloInizio = (this.itemsPerPage * Number(sessionStorage.getItem('approfondimentiEnPage'))) - this.itemsPerPage;
            let intervalloFine = Math.min((intervalloInizio + this.itemsPerPage), this.data.length);
            this.state = {intervalloInizio, intervalloFine, currentPage: Number(sessionStorage.getItem('approfondimentiEnPage'))};
        }else {
            this.state = {intervalloInizio: 0, intervalloFine: Math.min(this.itemsPerPage, this.data.length), currentPage: 1};
        }

        this.tipoApprofondimento = {
            '0': 'Risk content',
            '1': 'Istitutional content',
            '2': 'Technical files',
            '3': 'Dossier'
        };

    }

    setPage(page) {
        let intervalloInizio = (this.itemsPerPage * page) - this.itemsPerPage;
        let intervalloFine = Math.min((intervalloInizio + this.itemsPerPage), this.data.length);
        this.setState({
            intervalloInizio,
            intervalloFine,
            currentPage: page
        });
        if(typeof window !== 'undefined') {
            sessionStorage.setItem('approfondimentiEnPage', page);
        }

    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    filtra(filters) {
        this.data = this.fullData.filter(item => {
            if (filters.searchContent === "Solo notizie" && item.node.field_categoria_primaria !== "notizia")
                return false;
            if (filters.searchContent === "Solo comunicati stampa" && item.node.field_categoria_primaria !== "comunicato_stampa")
                return false;
            if (filters.searchDateInit && moment(filters.searchDateInit, "DD/MM/YYYY").isAfter(moment(item.node.field_data), "day"))
                return false;
            if (filters.searchDateFine && moment(filters.searchDateFine, "DD/MM/YYYY").isBefore(moment(item.node.field_data), "day"))
                return false;
            if (filters.searchAmbn.length)
                if (!item.node.relationships.field_ambito.some(i => filters.searchAmbn.includes('' + i.drupal_internal__tid)))
                    return false;
            if (filters.searchTerr.length)
                if (!item.node.relationships.field_territorio || !filters.searchTerr.includes('' + item.node.relationships.field_territorio.drupal_internal__tid))
                    return false;
            if (filters.searchRisk.length)
                if (!item.node.relationships.field_rischio.some(i => filters.searchRisk.includes('' + i.drupal_internal__tid)))
                    return false;
            if (filters.searchQuery && filters.searchQuery.trim().length)
                if ((!item.node.field_titolo_esteso || item.node.field_titolo_esteso.toLowerCase().indexOf(filters.searchQuery.toLowerCase()) < 0) && (!item.node.field_abstract || item.node.field_abstract.value.toLowerCase().indexOf(filters.searchQuery.toLowerCase()) < 0))
                    return false;
            //A questo punto ha superato tutti i filtri e verrà compreso nei risultati
            return true;
        });
        this.setPage(1);
    }

    render() {
        return (
            <PageContainerDPC basePathNavbar={process.env.PORTALE_URL} classeAggiuntivaSubnavbar={"has-header-subnavbar"}
                              menuItems={this.props.pageContext.menu}
                              title={this.props.data.node.title}
                              env={process.env.ENV}
                              lang={this.props.pageContext.lang}
                              pageId={this.props.data.node.drupal_internal__nid}
                              slugTraduzione={this.props.data.node.relationships.field_riferimento_traduzione ? this.props.data.node.relationships.field_riferimento_traduzione.fields.slug : ''}
                              minisiti={prop}
                              immagineShare={this.props.data.node.relationships?.field_immagine_dettaglio ? process.env.PORTALE_URL + this.props.data.node.relationships?.field_immagine_dettaglio?.relationships?.image?.localFile?.publicURL: process.env.PORTALE_URL + this.props.pageContext.socialImg?.relationships?.image?.localFile?.publicURL}
                              abstractShare={this.props.data.node.body?.processed ? this.props.data.node.body.processed.replace(/<\/?[^>]+(>|$)/g, "") : ''}
                              slug={process.env.PORTALE_URL + '/' + this.props.pageContext.lang + this.props.data.node.fields.slug}
                              menuFooterItems={this.props.pageContext.menuFooter}
            >
                <section className="container article-card-simple">
                    <h1>{this.props.data.node.field_titolo_esteso}</h1>

                    {(this.props.data?.node?.body || this.props.data?.node?.relationships?.field_immagine_anteprima) &&
                    <section className="primo-piano">
                        <div className="px-custom">
                            <div className="row">
                                <div className="col-12 col-xl-6">
                                    <img
                                        src={this.props.data?.node?.relationships?.field_immagine_anteprima?.relationships?.image?.localFile?.publicURL}
                                        className="img-fluid w-100 my-3"
                                        alt={this.props.data?.node?.relationships?.field_immagine_anteprima?.field_alt}/>
                                </div>
                                <div className="col-12 col-xl-6">
                                    <div className="my-3"
                                         dangerouslySetInnerHTML={{__html: this.props.data.node.body ? this.props.data.node.body.processed : ''}}/>
                                </div>
                            </div>
                        </div>
                    </section>
                    }

                    <FormFiltriDPC lang={'en'}
                        filtra={this.filtra}
                        ambiti={buildMenu(this.props.data.ambiti.edges, this.props.pageContext.lang, process.env.ENV)}
                        rischi={buildMenu(this.props.data.rischi.edges, this.props.pageContext.lang, process.env.ENV)}
                        territori={buildMenu(this.props.data.territori.edges, this.props.pageContext.lang, process.env.ENV)}
                    />

                    <div className="px-custom">
                        {
                            this.data
                                .slice(this.state.intervalloInizio, this.state.intervalloFine).map(node => {
                                return <CardElencoDPC
                                    title={node.node.field_titolo_esteso}
                                    body={node.node.field_abstract ? node.node.field_abstract.processed : ''}
                                    slug={getSlug('/' + this.props.pageContext.lang + node.node.fields.slug, 'Portale', node.node.relationships.field_sottodominio.name)}
                                    categoria={this.capitalizeFirstLetter(this.tipoApprofondimento[node.node.field_tipo_approfondimento])}
                                    data_creazione={node.node.field_data}
                                    lang={'en'}
                                />
                            })
                        }
                        {typeof window !== 'undefined' &&
                        <DpcPaginator setPage={this.setPage} key={this.data.length} numItems={this.data.length}
                                      itemsPerPage={this.itemsPerPage} currentPage={this.state.currentPage}/>
                        }
                        <PrintButtonDPC/>
                    </div>
                </section>
            </PageContainerDPC>
        );
    }

}

export default ElencoApprofondimenti;

export const query = graphql`
  query($slug: String!) {
    node:nodeElencoApprofondimenti (fields: { slug: { eq: $slug } }) {
      title
      field_titolo_esteso
      drupal_internal__nid
      fields {
        slug
      }
      body {
        value
        processed
      }
      relationships {
        field_immagine_anteprima {
          field_alt
          field_didascalia
          relationships {
            image:field_immagine {
              localFile {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 1100) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
        field_riferimento_traduzione {
          fields {
            slug
          }
        }
      }
    },
    allNodeApprofondimento(filter: {field_tipo_approfondimento: {in: ["2", "3"]}, relationships: {field_sottodominio: {name: {nin: ["Io Non Rischio", "IT Alert", "INR Attimo Decisivo"]}}}}) {
      edges {
        node {
          relationships {
            field_ambito {
              drupal_internal__tid
            }
            field_rischio {
              drupal_internal__tid
            }
            field_territorio {
              drupal_internal__tid
            }
            field_sottodominio {
              name
            }
            field_sottodominio {
              name
            }
            field_immagine_dettaglio {
        field_alt
        field_didascalia
        relationships {
          image:field_immagine {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
          }
          field_abstract {
            processed
            value
          }
          title
          field_titolo_esteso
          field_data
          field_categoria_primaria
          field_tipo_approfondimento
          field_codice_lingua
          fields {
            slug
          }
        }
      }
    },
    rischi: allTaxonomyTermRischio {
      edges {
        node {
          name: field_etichetta_en
          relationships {
            parent {
              name: field_etichetta_en
              drupal_internal__tid
            }
          }
          drupal_internal__tid
        }
      }
    },
    territori: allTaxonomyTermTerritorio {
      edges {
        node {
          name: field_etichetta_en
          relationships {
            parent {
              name: field_etichetta_en
              drupal_internal__tid
            }
          }
          drupal_internal__tid
        }
      }
    },
    ambiti: allTaxonomyTermAmbito {
      edges {
        node {
          name: field_etichetta_en
          relationships {
            parent {
              name: field_etichetta_en
              drupal_internal__tid
            }
          }
          drupal_internal__tid
        }
      }
    }
  }
`;
